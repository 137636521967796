exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-comics-js": () => import("./../../../src/pages/comics.js" /* webpackChunkName: "component---src-pages-comics-js" */),
  "component---src-pages-contacte-js": () => import("./../../../src/pages/contacte.js" /* webpackChunkName: "component---src-pages-contacte-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-newpage-js": () => import("./../../../src/pages/newpage.js" /* webpackChunkName: "component---src-pages-newpage-js" */),
  "component---src-pages-privacitat-js": () => import("./../../../src/pages/privacitat.js" /* webpackChunkName: "component---src-pages-privacitat-js" */),
  "component---src-templates-comedian-page-js": () => import("./../../../src/templates/comedian-page.js" /* webpackChunkName: "component---src-templates-comedian-page-js" */)
}

