// const React = require("react")
import React from "react";
// custom typefaces
// import "typeface-montserrat"
// import "typeface-merriweather"
import "@fontsource/plus-jakarta-sans";

// normalize CSS across browsers
// import "./src/css/normalize.css"

// custom CSS styles
import "./src/css/style.css"
import { createHeader } from "./src/scripts/comedian-header";

// import createHeader from "./src/scripts/comedian-header.js"

export const onRouteUpdate = ({ location, prevLocation }) => {
    if (location.pathname.startsWith('/comics/')) {
        const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        if (isMobile) {
            if (screen.orientation.type === 'landscape-secondary') createHeader(1000);
            else createHeader(400);
        } else {
            createHeader(2400);
        }
    }
}